<template>
  <div class="signUpPage-leftSide withSlider">
    <div class="whiteLogo-contaienr-updated">
      <img :src="require('../assets/images/latestLogo.png')" />
    </div>
    <b-carousel 
      :interval="slider.interval" 
      indicators 
      v-model="slider.current" 
      img-width="50%" 
      img-height="100%" 
      no-animation 
      @sliding-start="slider.animated=true" 
      @sliding-end="slider.animated=false"
    >
      <b-carousel-slide v-for="(slide, index) in slider.list" :key="index" :img-src="slide.bg_img">
        <img :src="slide.img" />
        <div class="whiteHeading">{{ slide.title }}</div>
        <div class="whiteText">{{ slide.desc }}</div>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: 'SipupSlider',
  data() {
    return {
      slider: {
        current: 0,
        animated: false,
        // interval: 100 * 1000, // 100 sec
        interval: 30 * 1000, // 30 sec
        // list: [
        //   {
        //     bg_img: require('../assets/images/SelectNumberBgImg.webp'),
        //     img: require('../assets/images/selectNumberLeftIMG.png'),
        //     title: 'Gain recognition',
        //     desc: `To achieve measurable improvements, it's essential to avoid speculation and rely on real-time monitoring of team and individual metrics for better performance management.`
        //   },
        //   {
        //     bg_img: require('../assets/images/SignUpStap2BgIMG.webp'),
        //     img: require('../assets/images/step2LeftSideIMG.png'),
        //     title: 'Link to your devices',
        //     desc: `To improve call quality, connect VoIP to important cloud-based programs, including CRM and help desk applications, for smoother operations and enhanced data management.`
        //   },
        //   {
        //     bg_img: require('../assets/images/purple.png'),
        //     img: require('../assets/images/step3LeftSideIMG.png'),
        //     title: 'Set up quickly',
        //     desc: `Our cloud-based call center solution offers hassle-free global communication, without any need for hardware, so you can strike up a conversation anywhere in the world, right now.`
        //   },
        //   {
        //     bg_img: require('../assets/images/SignUpStap4BgIMG.png'),
        //     img: require('../assets/images/step4LeftSideIMG.png'),
        //     title: 'Liberating Creativity',
        //     desc: `Enjoy the freedom of remote work with a mobile office setup that allows you to use your smartphones, tablets, and laptops from anywhere, whether it's a coffee shop, park, or on the go.`
        //   }
        // ]
        list: [
          {
            bg_img: require('../assets/images/v2_signup_images/neeli.png'),
            img: require('../assets/images/v2_signup_images/neeli_1.png'),
            title: 'Unlock business insights',
            desc: `Gain insights with our VoIP service! Track call metrics, analyze trends, and optimize workflows. Drive better business outcomes with advanced analytics.`
          },
          {
            bg_img: require('../assets/images/v2_signup_images/peeli.png'),
            img: require('../assets/images/v2_signup_images/peeli_1.png'),
            title: 'Link to your devices',
            desc: `Control devices easily with our VoIP service! Add, remove, or modify as needed. Upgrade phones, switch computers, or add IP phones in a snap. Say goodbye to restrictions—hello flexibility!`
          },
          {
            bg_img: require('../assets/images/v2_signup_images/sabaz.png'),
            img: require('../assets/images/v2_signup_images/sabaz_1.png'),
            title: 'Stay connected anywhere',
            desc: `Experience unified communication with our VoIP service! Sync calls, messages, and video across all devices. Stay connected and productive, no matter what.`
          },
          {
            bg_img: require('../assets/images/v2_signup_images/kali.png'),
            img: require('../assets/images/v2_signup_images/kali_1.png'),
            title: 'Gain recognition',
            desc: `Integrate with CRM systems for automatic data syncing. No manual entry—just real-time updates for faster responses and better service.`
          },
        ]
      },
    }
  },
}
</script>
