<template>
  <!-- forgot_password -->
  <div v-if="conditions.screen==screens.forgot_password" class="dialer-login-form"> 
    <div class="signUpPage-container">
      <SipupSlider />
      <div class="signUpPage-rightSide">
        <div class="signUpPage-rightSide-inner d-flex align-items-center">
          <b-form class="w-100" @submit.prevent="forgotpassword()">
            <div class="m-auto">
              <div class="mainHeading mt-16px">Forgot password?</div>
              <div class="subIndicatorText mt-8px">
                <template v-if="conditions.notfication_email">Step 2</template>
                <template v-else>Step 1</template>
                <!-- <template>step 1</template> -->
              </div>
              <div class="SubHeading text-center mt-12px">
                <template v-if="conditions.notfication_email">
                  Enter the email address linked to your account or enter an admin email address
                </template>
                <template v-else>Enter your email or username</template>
              </div>
              <b-alert variant="danger" :show="!!api.forgot.error_message">{{ api.forgot.error_message }}</b-alert>
              <div v-if="conditions.notfication_email" class="mainInput-container mt-40px mb-40px with-error">
                <label>Email</label>
                <div :class="`mainInput ${api.forgot.send?'disabled':''}`"> 
                  <b-form-input 
                    type="text" 
                    placeholder="Enter your email" 
                    :disabled="api.forgot.send" 
                    v-model="forms.forgot.notfication_email" 
                  />
                </div>
                <template v-if="(forms.forgot.submitted && $v.forms.forgot.notfication_email.$invalid) || api.forgot.validation_errors.notfication_email">
                  <b-icon icon="info-circle-fill" variant="danger" class="danger-info-icon"/>
                  <div class="errorTemplate-v2">
                    <p class=" animated bounceIntop">
                      <span v-if="!$v.forms.forgot.notfication_email.required">* Email is required</span>
                      <span v-if="!$v.forms.forgot.notfication_email.email">* Email is invalid</span>
                      <span v-for="(em, i) in api.forgot.validation_errors.email" :key="i">* {{ em }}</span>
                    </p>
                  </div>
                </template>
              </div>
              <div v-else class="mainInput-container mb-40px with-error mt-40px">
                <label>Username / Email</label>
                <div :class="`mainInput ${api.forgot.send?'disabled':''}`"> 
                  <b-form-input 
                    type="text" 
                    placeholder="Enter your username / email" 
                    :disabled="api.forgot.send" 
                    v-model="forms.forgot.email" 
                  />
                </div>
                <template v-if="(forms.forgot.submitted && $v.forms.forgot.email.$invalid) || api.forgot.validation_errors.email">
                  <b-icon id="su-email" icon="info-circle-fill" variant="danger" class="danger-info-icon"/>
                  <div class="errorTemplate-v2">
                    <p class=" animated bounceIntop">
                      <span v-if="!$v.forms.forgot.email.required">* Email is required</span>
                      <span v-if="!$v.forms.forgot.email.email">* Email is invalid</span>
                      <span v-for="(em, i) in api.forgot.validation_errors.email" :key="i">* {{ em }}</span>
                    </p>
                  </div>
                </template>
              </div>
              <div v-if="GET_IS_RECAPTCHA_ENABLED" class="h-78px mb-40px position-relative">
                <app-recaptcha 
                  @load="forms.forgot.recaptcha=false"
                  @verify="forms.forgot.recaptcha=true"
                  @expire="forms.forgot.recaptcha=false"
                  @fail="forms.forgot.recaptcha=false"
                />
                <template v-if="(forms.forgot.submitted && $v.forms.forgot.recaptcha.$invalid)">
                  <div class="errorTemplate-v2" style="width:270px">
                    <p class=" animated bounceIntop">
                      <span v-if="!$v.forms.forgot.recaptcha.required">* Recaptcha is require</span>
                    </p>
                  </div>
                </template>
              </div>
              <!-- buttons -->
              <b-button :disabled="api.forgot.send" class="fullBlackBTN" type="submit" variant="primary">
                <app-spinner v-if="api.forgot.send" />
                <template v-else>Reset password</template>
              </b-button>
            </div>
          </b-form>
        </div>
        <div class="bottomSection">
          <span class="hideOnMobile">Oh!, I remember my password.</span> Take me back to 
          <a @click="$router.push({name:routesNames.login})" class="mx-2 font-weight-bold font-size-14 color-primary">Log In</a>
        </div>
      </div>
    </div>
  </div>
  <!-- pin -->
  <div v-else-if="conditions.screen==screens.pin" class="dialer-login-form">
    <div class="signUpPage-container">
      <SipupSlider />
      <div class="signUpPage-rightSide">
        <div class="signUpPage-rightSide-inner">
          <b-form class="w-100 my-auto d-block" @submit.prevent="verifyPincode()">
            <img src="../assets/images/checkYourEmailIMG.png" alt="icon" class="newPasswordScreenImage"/>
            <div class="mainHeading mt-16px">Forgot password?</div>
            <!-- <div class="subIndicatorText mt-8px">step 3</div> -->
            <!-- <div class="subIndicatorText mt-8px">step 2</div> -->
            <div class="subIndicatorText mt-8px">
              <template v-if="conditions.notfication_email">Step 3</template>
              <template v-else>Step 2</template>
            </div>
            <div class="mainHeading-smallerVersion mt-16px">Check your email!</div>
            <div class="SubHeading text-center mt-12px">A pin has been sent to yours or an admin email please enter it below</div>
            <div class="latestPin-container mt-70px bigger with-error">
              <b-alert variant="danger" :show="!!api.pin.error_message">{{ api.pin.error_message }}</b-alert>
              <b-alert class="mb-16px" :show="!!api.resend.error_message" variant="danger">{{ api.resend.error_message }}</b-alert>
              <b-alert class="mb-16px" :show="!!api.resend.success_message" variant="success">{{ api.resend.success_message }}</b-alert>
              <div class="latestPin-inner pin">
                <app-pin-code 
                  class="input" 
                  :numberslength="6" 
                  :disabled="api.pin.send" 
                  @complete="forms.pin.pincode=$event;verifyPincode();" 
                />
                <template v-if="(forms.pin.submitted && $v.forms.pin.pincode.$invalid) || api.pin.validation_errors.pincode">
                  <b-icon id="su-email" icon="info-circle-fill" variant="danger" class="danger-info-icon"/>
                  <div class="errorTemplate-v2">
                    <p class=" animated bounceIntop">
                      <span v-if="!$v.forms.reset.pincode.required">* Pincode is required</span>
                      <span v-else-if="!$v.forms.reset.pincode.valid">* Pincode should be only 6 digits</span>
                      <span v-for="(em, i) in api.reset.validation_errors.pincode" :key="i">* {{ em }}</span>
                    </p>
                  </div>
                </template>
              </div>
            </div>
            <div class="w-100 d-flex justify-content-center align-items-center mt-20px">
              <span :class="`cursor_pointer min-width-185px d-flex ${api.resend.count_down.seconds!=0?'justify-content-between':'justify-content-center'} align-items-center`" @click="api.resend.count_down.seconds!=0 ? '' : resend()">
                <span :aria-disabled="api.resend.count_down.seconds!=0" :disabled="api.resend.count_down.seconds!=0">
                  <b-icon class="me-2"
                    icon="arrow-clockwise" 
                    :animation="api.resend.send?'spin':''" 
                    v-b-tooltip.hover 
                    title="Resend email to confirm" 
                  />
                  <span class="me-2">Resend email</span>
                </span>
                <span v-if="api.resend.count_down.seconds!=0">{{ api.resend.count_down.seconds | duration_format({ format: 'mm:ss', default: '00:00' }) }}</span>
              </span>
            </div>
            <div v-if="api.pin.count!=0" class="attemptesIndicatorText mt-40px">{{3-api.pin.count+1}} attempts remaining</div>
            <!-- buttons -->
            <b-button 
              class="fullBlackBTN mt-40px" 
              :disabled="api.pin.send" 
              @click="conditions.screen=screens.forgot_password" 
              type="button" 
              variant="primary"
            >
              Back
            </b-button>
          </b-form>
        </div>
        <div class="bottomSection mt-40px">
          <span class="hideOnMobile">Oh!, I remember my password.</span> Take me back to  
          <a @click="api.pin.send ? '' : $router.push({name:routesNames.login})" class="mx-2 font-weight-bold font-size-14 color-primary">Log In</a>
        </div>
      </div>
    </div>
  </div>
  <!-- reset -->
  <div v-else-if="conditions.screen==screens.reset" class="dialer-login-form">
    <div class="signUpPage-container">
      <SipupSlider />
      <div v-if="api.reset.status!=1" class="signUpPage-rightSide">
        <div class="signUpPage-rightSide-inner d-flex align-items-center">
          <b-form class="w-100" @submit.prevent="reset()">
            <b-alert :show="!!api.reset.error_message" variant="danger">{{ api.reset.error_message }}</b-alert>
            <div class="mainHeading mt-16px">Forgot password?</div>
            <!-- <div class="subIndicatorText mt-8px">step 4</div> -->
            <!-- <div class="subIndicatorText mt-8px">step 3</div> -->
            <div class="subIndicatorText mt-8px">
              <template v-if="conditions.notfication_email">Step 4</template>
              <template v-else>Step 3</template>
            </div>
            <div class="mainHeading-smallerVersion mt-16px">Create new password</div>
            <div class="SubHeading text-center mt-12px">Enter a strong and memorable password</div>
            <div class="mainInput-container mt-40px mb-20px with-error">
              <label>New password</label>
              <div :class="`mainInput password ${api.reset.send?'disabled':''}`"> 
                <b-form-input 
                  :disabled="api.reset.send" 
                  :type="forms.reset.password_show?'text':'password'" 
                  v-model="forms.reset.password" 
                />
                <span class="pass-icon">
                  <b-icon 
                    class="dialer-showpassword-icon cursor_pointer" 
                    :icon="forms.reset.password_show ? 'eye-slash-fill' : 'eye-fill'" 
                    @click="api.reset.send ? '' : forms.reset.password_show=!forms.reset.password_show" 
                  />
                </span>
              </div>
              <template v-if="(forms.reset.submitted && $v.forms.reset.password.$invalid) || api.reset.validation_errors.password">
                <b-icon icon="info-circle-fill" variant="danger" class="danger-info-icon"/>
                <div class="errorTemplate-v2">
                  <p class=" animated bounceIntop">
                    <span v-if="!$v.forms.reset.password.required">* Password is required</span>
                    <span v-else-if="!$v.forms.reset.password.capitalalphabet">* Password required at least one capital character</span>
                    <span v-else-if="!$v.forms.reset.password.smallalphabet">* Password required at least one small character</span>
                    <span v-else-if="!$v.forms.reset.password.numbers">* Password required at least one integer character</span>
                    <span v-else-if="!$v.forms.reset.password.specialcharacter">* Password required at least one special character</span>
                    <span v-else-if="!$v.forms.reset.password.minLength">
                      * Password is minimum {{$v.forms.reset.password.$params.minLength.min}} is required
                    </span>
                    <span v-else-if="!$v.forms.reset.password.maxLength">
                      * Password is maximum {{$v.forms.reset.password.$params.maxLength.max}} character
                    </span>
                    <span v-for="(em, i) in api.reset.validation_errors.password" :key="i">* {{ em }}</span>
                  </p>
                </div>
              </template>
            </div>
            <div class="mainInput-container mt-40px mb-20px with-error">
              <label>Confirm password</label>
              <div :class="`mainInput password ${api.reset.send?'disabled':''}`"> 
                <b-form-input 
                  :disabled="api.reset.send" 
                  :type="forms.reset.confirm_password_show?'text':'password'" 
                  v-model="forms.reset.confirm_password" 
                />
                <span class="pass-icon">
                  <b-icon 
                    class="dialer-showpassword-icon cursor_pointer" 
                    :icon="forms.reset.confirm_password_show ? 'eye-slash-fill' : 'eye-fill'" 
                    @click="api.reset.send ? '' : forms.reset.confirm_password_show=!forms.reset.confirm_password_show" 
                  />
                </span>
              </div>
              <template v-if="forms.reset.submitted && $v.forms.reset.confirm_password.$invalid">
                <b-icon id="su-email" icon="info-circle-fill" variant="danger" class="danger-info-icon"/>
                <div class="errorTemplate-v2">
                  <p class=" animated bounceIntop">
                    <span v-if="!$v.forms.reset.password.required">* Confirm Password is required</span>
                    <span v-else-if="!$v.forms.reset.password.sameAsPassword">* Confirm password should be same as password</span>
                  </p>
                </div>
              </template>
            </div>
            <div class="PasswordLength-LineContainer mt-0 mb-16px">
              <div class="line-wrap">
                <div 
                  :class="`line-itself ${(passwordStrength/5 * 100) > 60 ? 'strong' : ''} `" 
                  :style="`width: calc(${passwordStrength/5 * 100}% + ${passwordStrength>0 ? '2' : '0'}px);`"
                />
              </div>
              <small class="line-text">{{passwordStrength | filterPasswordStrengthText}}</small>
            </div>
            <div style="display: none !important;" class="button-container-v2 mb-32px">
              <div class="button-container-inner">
                <b-button :disabled="api.reset.send" class="login-button-v2" type="submit" variant="primary">
                  <app-spinner v-if="api.reset.send" />
                  <template v-else>Reset password</template>
                </b-button>
              </div>
            </div>
            <b-button :disabled="api.reset.send" class="fullBlackBTN mt-40px" type="submit" variant="primary">
              <app-spinner v-if="api.reset.send" />
              <template v-else>Reset password</template>
            </b-button>
            <b-button @click="conditions.screen=screens.pin" class="BlackOutlineBTN mt-20px" type="submit" > 
              Back
            </b-button>
          </b-form>
        </div>
        <div class="bottomSection mt-40px">
          <span class="hideOnMobile">Oh!, I remember my password. </span>Take me back to 
          <a @click="$router.push({name:routesNames.login})" class="mx-2 font-weight-bold font-size-14 color-primary">Log In</a>
        </div>
      </div>
      <div v-else class="signUpPage-rightSide">
        <div class="signUpPage-rightSide-inner">
          <img src="../assets/images/thumbCongrtulations.svg" alt="icon" class="newPasswordScreenImage"/>
          <div class="mainHeading mt-16px">
            Password reset completed
          </div>
          <button class="fullBlackBTN mt-40px" @click="$router.push({name:routesNames.login})">Go to Login</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { API, LOGGER } from "../service";
import { mapGetters } from 'vuex';
import { filterPasswordStrengthText } from '@/bootstrap/filters/filterPasswordStrengthText';
import SipupSlider from '../components/SipupSlider.vue'
import requiredIf from 'vuelidate/lib/validators/requiredIf';
import sameAs from 'vuelidate/lib/validators/sameAs';
import { GET_IS_RECAPTCHA_ENABLED } from '@/store/constants/getters';
import { routes_names } from '@/router/constants/routes-names';

const screens = {
  forgot_password: 'forgot_password',
  pin: 'pin',
  reset: 'reset',
}

export default {
  name: 'ForgetPassword',
  components: {
    SipupSlider
  },
  data(){
    return {
      forms: {
        forgot: this.$app.form({
          data: {
            email: '',
            notification_email: '',
            recaptcha: false,
          }
        }),
        pin: this.$app.form({
          data: {
            pincode: '',
          }
        }),
        reset: this.$app.form({
          data: {
            password: '',
            password_show: false,
            confirm_password: '',
            confirm_password_show: false,
          }
        }),
      },
      conditions: {
        screen: screens.forgot_password,
        notfication_email: false,
      },
      api: {
        forgot: this.$app.api({
          error_message: true,
          validation_errors: true,
        }),
        pin: this.$app.api({
          error_message: true,
          validation_errors: true,
          count: true,
        }),
        reset: this.$app.api({
          error_message: true,
          validation_errors: true,
          status: true,
        }),
        resend: this.$app.api({
          error_message: true,
          success_message: true,
          validation_errors: true,
          request_time: true,
          count_down: true,
        }),
      },
    }
  },
  computed: {
    ...mapGetters([
      GET_IS_RECAPTCHA_ENABLED
    ]),
    routesNames(){ return routes_names },
    screens(){return screens},
    passwordStrength(){
      const password = this.forms.reset.password
      return ~~[/[A-Z]/.test(password),/[0-9]/.test(password),/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/.test(password),/[a-z]/.test(password),password.length > 11,].reduce((c,v)=>v?c+1:c)
    },
  },
  filters: {
    filterPasswordStrengthText,
  },
  watch: {
    "conditions.screen": {
      immediate: true,
      handler(screen){
        let vm = this
        LOGGER.log('screen',screen)
        this.$emit('screen',screen)
        if(screen==screens.forgot_password){
          vm.api.forgot.reset()
        } else if (screen==screens.pin){
          vm.api.pin.reset()
          vm.api.resend.reset()
        } else if (screen==screens.reset){
          vm.api.reset.reset()
        }
      }
    }
  },
  validations: {
    forms: {
      forgot: {
        email: {
          required,
        },
        notfication_email: {
          required: requiredIf(function(){
            return this.conditions.notfication_email
          })
        },
        recaptcha: {
          required: function(value){ 
            return this.GET_IS_RECAPTCHA_ENABLED ? value : true 
          },
        },
      },
      pin: {
        pincode: {
          required,
          valid: (value)=>{
            return value.length===6
          },
        },
      },
      reset: {
        password: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(32),
          capitalalphabet: function(value){
            return /[A-Z]/.test(value)
          },
          smallalphabet: function(value){
            return /[a-z]/.test(value)
          },
          numbers: function(value){
            return /[0-9]/.test(value)
          },
          specialcharacter: function(value){
            return /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/.test(value)
          },
        },
        confirm_password: {
          required: required, 
          sameAsPassword: sameAs('password')
        }
      },
    },
  },
  methods: {
    async forgotpassword(){
      this.forms.forgot.submitted=true
      this.$v.forms.forgot.$touch()
      if(this.api.forgot.send || this.$v.forms.forgot.$invalid) return;
      try {
        this.api.forgot.send=true
        this.api.forgot.error_message = ''
        this.api.forgot.validation_errors = {}
        const { data } = await API.endpoints.auth.forgotpassword({
          username: this.forms.forgot.email,
          type: 'forget',
          ...this.conditions.notfication_email ? {
            notification_email: this.forms.forgot.notfication_email,
          } : {},
        })
        if(data.type=='sub-user'){
          this.conditions.notfication_email=true
        } else {
          this.conditions.screen=screens.pin
        }
      } catch (ex) {
        this.api.forgot.validation_errors = ex.own_errors || {}
        this.api.forgot.error_message = ex.own_message || ex.message
      } finally {
        this.forms.forgot.submitted=false
        this.api.forgot.send=false
      }
    },
    async verifyPincode(){
      this.forms.pin.submitted=true
      this.$v.forms.pin.$touch()
      if(this.api.pin.send || this.$v.forms.pin.$invalid || this.api.pin.count>3) return;
      try {
        this.api.pin.send=true
        this.api.pin.error_message=''
        await API.endpoints.auth.forgotpassword({
          username: this.forms.forgot.email,
          type: 'verify',
          ...this.conditions.notfication_email ? {
            notification_email: this.forms.forgot.notfication_email,
          } : {},
          pincode: this.forms.pin.pincode,
        })
        this.conditions.screen=screens.reset;
      } catch (ex) {
        this.api.pin.increment()
        this.api.pin.error_message = ex.own_message
      } finally {
        this.api.pin.send=false
        this.forms.pin.submitted=false
      }
    },
    async resend(){
      if(this.api.resend.send) return;
      try {
        if(this.api.resend.request_time.compare('minutes',3)<3) throw new Error('Wait for 3 Minutes')
        this.api.resend.send=true
        this.api.resend.error_message=''
        this.api.resend.success_message=''
        await API.endpoints.auth.forgotpassword({
          username: this.forms.forgot.email,
          type: 'resend',
          ...this.conditions.notfication_email ? {
            notification_email: this.forms.forgot.notfication_email,
          } : {},
        })
        this.api.resend.request_time.setTime()
        this.api.resend.count_down.start(3,'minute')
        this.api.resend.success_message='Successfully sent'
        this.api.resend.emptySuccessMessage(3)
      } catch (ex) {
        this.api.resend.error_message = ex.own_message || ex.message
      } finally {
        this.api.resend.send=false
      }
    },
    async reset(){
      this.forms.reset.submitted=true
      this.$v.forms.reset.$touch()
      if(this.api.reset.send || this.$v.forms.reset.$invalid) return;
      try {
        this.api.reset.send=true
        this.api.reset.error_message = ''
        this.api.reset.validation_errors = {}
        this.api.reset.status = 0
        await API.endpoints.auth.forgotpassword({
          username: this.forms.forgot.email,
          type: 'reset',
          ...this.conditions.notfication_email ? {
            notification_email: this.forms.forgot.notfication_email,
          } : {},
          pincode: this.forms.pin.pincode,
          password: this.forms.reset.password,
        })
        this.api.reset.status = 1
      } catch (ex) {
        this.api.reset.status = 2
        this.api.reset.error_message = ex.own_message
        this.api.reset.validation_errors = ex.own_errors
      } finally {
        this.api.reset.send=false
        this.forms.reset.submitted=false
      }
    },
  },
}
</script>

<style>

</style>